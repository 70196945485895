html,
body {
  width: 100%;
  height: 100%;
}

#root {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

header,
footer {
  flex-shrink: 0;
}

main {
  flex-grow: 1;
}
