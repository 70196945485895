.page-404 {
  @media (min-width: 992px) {
    .section-1 {
      background: linear-gradient(90deg, #fff 50%, rgba(250, 250, 250, 1) 0);
    }

    .section-2,
    .section-3 {
      background-size: 6px 6px;
      background-image: linear-gradient(
        -45deg,
        #fafafa 46%,
        rgba(48, 51, 51, 0.7) 49%,
        rgba(48, 51, 51, 0.7) 51%,
        #fafafa 55%
      );
    }

    .section-2 > .container-fluid {
      background: linear-gradient(90deg, #ffffff 50%, rgba(250, 250, 250, 0) 0);
    }

    .section-3 > .container-fluid {
      background: linear-gradient(90deg, #fafafa 50%, rgba(250, 250, 250, 0) 0);
    }
  }

  .text-outline-socgen {
    text-shadow: -1px 0 #e60028, 0 1px #e60028, 1px 0 #e60028, 0 -1px #e60028;
  }
}
